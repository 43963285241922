<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome to DetainEHR! 👋🏻
          </p>
          <p class="mb-2">
            Please sign-in to your account and begin charting.
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
          >
            <site-select
              :site="site"
              @site-select="updateSite"
            >
            </site-select>

            <v-text-field
              id="loginInputEmail"
              class="mb-3"
              v-model="userName"
              outlined
              label="User Name"
              placeholder="John.Doe"
              hide-details
            ></v-text-field>

            <v-text-field
              id="loginInputPassword"
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center  flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>
              <v-spacer>
              </v-spacer>
              <router-link
                :to="{name:'mfa-login'}"
                class="ms-3 mt-1"
              >
                Use Authenticator App?
              </router-link>
              <!-- forgot link -->
              <router-link
                :to="{name:'auth-forgot-password', params: { email: userName, token: ''}}"
                class="mt-1"
              >
                Register or Reset Password
              </router-link>
            </div>

            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="loginGetOTPBtn"
                  block
                  color="primary"
                  class="mt-6"
                  v-bind="attrs"
                  v-on="on"
                  @click="getOTP()"
                >
                  Login
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Privacy and HIPPA Policy
                </v-card-title>

                <v-card-text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-text-field
                    v-model="otp"
                    outlined
                    label="Pass Code"
                    placeholder="Enter OTP from your email"
                  >
                  </v-text-field>

                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    text
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    id="loginFinalBtn"
                    color="primary"
                    text
                    :disabled="buttonIsDisabled"
                    @click.once="agree = true"
                    @click="submitForm()"
                  >
                    I accept
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref, onUnmounted } from '@vue/composition-api'
import SiteSelect from '@/components/site/SiteSelect.vue'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'

export default {
  components: {
    SiteSelect,
  },
  data() {
    return {
      buttonIsDisabled: true,
      otp: '',
      site: null,
      siteId: '',
      userName: '',
      password: '',
      isPasswordVisible: false,
      errorMessages: {
        userName: [],
        password: [],
        validation: [],
      },
      dialog: false,
      agree: false,
    }
  },
  setup() {
    const AUTH_APP = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP)) {
      store.registerModule(AUTH_APP, authenticationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP)) store.unregisterModule(AUTH_APP)
    })

    const { tokens, EntryDTO, postAuthAttempt, errorMessages, rolePackage, userData, oTPLogin } =
      useAuthenticationService()

    // Template Ref
    const loginForm = ref(null)

    const isPasswordVisible = ref(false)

    const vm = getCurrentInstance().proxy

    const { router } = useRouter()

    const RedirectToDashboard = () => {
      try {
        const userAbility = JSON.parse(localStorage.getItem('userAbility'))
        vm.$ability.update(userAbility)

        router.push('/dashboards/home')
      } catch (exception) {
        alert(`Redirection Failed due to ${exception}`)
      }
    }

    const validate = () => {
      if (loginForm.value.validate(EntryDTO)) {
        return true
      }

      return false
    }

    return {
      isPasswordVisible,
      errorMessages,

      oTPLogin,
      postAuthAttempt,
      EntryDTO,
      tokens,
      rolePackage,

      userData,

      router,
      validate,

      RedirectToDashboard,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
  watch: {
    otp(newValue, oldValue) {
      this.buttonIsDisabled = false
    },
  },
  methods: {
    updateSite(siteDTO) {
      this.site = siteDTO.value
      this.siteId = siteDTO.value.id
    },
    updateUser(userName) {
      this.userName = userName
    },
    updatePassword(password) {
      this.password = password
    },
    async getOTP() {
      try {
        const EntryDTO = { Email: this.userName, Password: this.password, siteId: this.siteId }

        if (!this.validate(EntryDTO)) {
          return
        }

        await this.postAuthAttempt(EntryDTO)
      } catch (error) {
        alert(`login vue error: ${error}`)
      }
    },
    async submitForm() {
      if (this.agree) {
        const SecondStep = { Email: this.userName, Password: this.otp.trim(), siteId: this.siteId }

        await this.oTPLogin(SecondStep)

        this.RedirectToDashboard()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
